import plural from 'plural-ru';

const capitalizeText = (text = '') => {
  return text?.charAt(0).toUpperCase() + text?.toLowerCase().slice(1);
};

let pluralRule = new Intl.PluralRules('ru');
const intlRelativeTimeFormat = new Intl.RelativeTimeFormat('ru', {
  localeMatcher: 'best fit',
  numeric: 'always',
  style: 'long',
});

function pluralDays(item = 0) {
  let form = pluralRule.select(item);
  let word;
  switch (form) {
    case 'one':
      word = 'остался';
      break;
    default:
      word = 'осталось';
      break;
  }

  return intlRelativeTimeFormat.format(item, 'days').replace('через', word);
}

function pluralMonth(item) {
  return intlRelativeTimeFormat.format(item, 'months').replace('через', 'на');
}

function preparePrice(number, noTransform) {
  if (noTransform) {
    return number.toString();
  }
  try {
    return number.toLocaleString('ru-RU');
  } catch {
    return number;
  }
}

function parseShortDate(str) {
  const [date] = new Date(str)
    .toLocaleString('ru', {
      month: '2-digit',
      day: '2-digit',
    })
    .split(' ');
  return date;
}

function parseDate(str) {
  if (!str) {
    return;
  }
  const [weekDay, day, month] = new Date(str)
    .toLocaleString('ru', {
      month: 'long',
      weekday: 'long',
      day: '2-digit',
    })
    .split(' ');

  return [weekDay.slice(0, -1), day, month];
}

const validateEmail = (value) =>
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/g.test(
    value,
  );

const validateFIO = (value) => value.length >= 2;

const validatePhone = (value, formatedValue, country) => {
  if (country.name === 'Russia') {
    const formatLength = country.format.length;
    return formatedValue.length === formatLength;
  }

  return value.length - country.dialCode.length > 5;
};

const generateTags = (city, title, additionalTags = '') => {
  let tags = [];
  const regexs = {
    master: /МАСТЕР/i,
    dod: /ДЕНЬ\sОТКРЫТЫХ\sДВЕРЕЙ/i,
    demo: /ПРЕЗЕНТАЦИЯ/i,
  };
  if (regexs.master.test(title)) {
    if (city === 'MSK') tags.push('МК Мск');
    else if (city === 'SPB') tags.push('МК Спб');
    else if (city === 'ONLINE') tags.push('МК Онлайн');
  } else if (regexs.dod.test(title)) {
    if (city === 'MSK') tags.push('ДОД Мск');
    else if (city === 'SPB') tags.push('ДОД Спб');
    else if (city === 'IST') tags.push('ДОД Стамбул');
    else if (city === 'EVN') tags.push('ДОД Ереван');
    else if (city === 'ONLINE' && !/DATA SCIENCE/i.test(title)) {
      tags.push('ДОД Онлайн');
    }
  } else if (regexs.demo.test(title)) {
    tags.push('demoday');
  }

  if (additionalTags.length) {
    tags = tags.concat(additionalTags.split(',').map((tag) => tag.trim()));
  }

  return tags;
};

const generateProgram = (title) => {
  let program = 'JS';
  const dataScience = /.*(data\sscience|ds|python).*/i;
  if (dataScience.test(title)) program = 'DS';
  return program;
};

const getProgram = (titleEnum) => {
  let program = 'JS';
  if (titleEnum.includes('DS')) {
    program = 'DS';
  }
  if (titleEnum.includes('UX')) {
    program = 'UX';
  }
  return program;
};

const getType = (type = 'bid') => {
  switch (type) {
    case 'consultation':
      return 'консультация';
    case 'paymentMethod':
      return 'заявка';
    default:
      return 'заявка';
  }
};

const getUtm = () => {
  const utms = {};
  const searchParams = new URLSearchParams(window.location.search);
  for (const key of searchParams.keys()) {
    if (key.toLowerCase().startsWith('utm_')) {
      utms[key] = new URLSearchParams(window.location.search).get(key);
    }
  }
  return utms;
};

const pluralPlaces = (amount) => {
  if (typeof amount === 'number') {
    return amount
      ? `${amount} ${plural(amount, 'место', 'места', 'мест')}`
      : 'МЕСТ НЕТ';
  }
  return null;
};

/**
 * Structure file query information to object
 * @param {Array} allFile.node array of allFile from GraphQL query File Edges
 * @returns {Object} object, with relative path + name as a key and image data as value
 */
const getImageDataMapByFileEdges = (allFileNode) =>
  allFileNode.reduce((acc, cur) => {
    const { relativeDirectory, name } = cur.node;
    const path = `${relativeDirectory}/${name}`;
    acc[path] = cur.node;
    return acc;
  }, {});

/**
 * Check if strapi **object** has empty strings or null values. Includes recursion for inner objects.
 * @param {Object} strapiObj data object from strapi
 * @returns {boolean} **true** if strapi object has empty strings / null values / no values
 */
const emptyValuesInStrapiObj = (strapiObj) => {
  const valuesArr = Object.values(strapiObj);
  if (!valuesArr.length) return true;

  return valuesArr.some((value) => {
    if (value === null || value === undefined) return true;

    if (typeof value === 'string') return value.trim() === '';

    if (typeof value === 'object' && !Array.isArray(value)) {
      return emptyValuesInStrapiObj(value);
    }

    return false;
  });
};

const getAmoFormat = (event) => {
  switch (event.city) {
    case 'MSK':
      return 'офлайн МСК';
    case 'SPB':
      return 'офлайн СПБ';
    case 'IST':
      return 'офлайн';
    default:
      return 'онлайн';
  }
};

export {
  parseShortDate,
  pluralMonth,
  pluralDays,
  preparePrice,
  parseDate,
  validateEmail,
  validateFIO,
  validatePhone,
  generateTags,
  generateProgram,
  getUtm,
  getType,
  pluralPlaces,
  emptyValuesInStrapiObj,
  getImageDataMapByFileEdges,
  capitalizeText,
  getAmoFormat,
  getProgram,
};
