exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contract-agreements-js": () => import("./../../../src/pages/contract_agreements.js" /* webpackChunkName: "component---src-pages-contract-agreements-js" */),
  "component---src-pages-datascience-basics-js": () => import("./../../../src/pages/datascience-basics.js" /* webpackChunkName: "component---src-pages-datascience-basics-js" */),
  "component---src-pages-datascience-js": () => import("./../../../src/pages/datascience.js" /* webpackChunkName: "component---src-pages-datascience-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-javascript-basics-js": () => import("./../../../src/pages/javascript-basics.js" /* webpackChunkName: "component---src-pages-javascript-basics-js" */),
  "component---src-pages-job-report-2021-js": () => import("./../../../src/pages/job-report2021.js" /* webpackChunkName: "component---src-pages-job-report-2021-js" */),
  "component---src-pages-job-report-2023-js": () => import("./../../../src/pages/job-report2023.js" /* webpackChunkName: "component---src-pages-job-report-2023-js" */),
  "component---src-pages-msk-js-js": () => import("./../../../src/pages/msk-js.js" /* webpackChunkName: "component---src-pages-msk-js-js" */),
  "component---src-pages-online-js-js": () => import("./../../../src/pages/online-js.js" /* webpackChunkName: "component---src-pages-online-js-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-spb-js-js": () => import("./../../../src/pages/spb-js.js" /* webpackChunkName: "component---src-pages-spb-js-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */)
}

