import Cookies from 'universal-cookie';

const AB_GROUP_NAME = 'onlineTestGroup';
const AB_CONTROL_GROUP_NAME = 'control';
const AB_TARGET_GROUP_NAME = 'target';
const AB_COOKIE_TTL = '2023-01-01';
const AB_PROPROTION = 0.5;

const cookies = new Cookies();

export const setupABTest = () => {
  if (!isUserInTest()) {
    Math.random() < AB_PROPROTION
      ? cookies.set(AB_GROUP_NAME, AB_CONTROL_GROUP_NAME, {
          path: '/',
          expires: new Date(AB_COOKIE_TTL),
        })
      : cookies.set(AB_GROUP_NAME, AB_TARGET_GROUP_NAME, {
          path: '/',
          expires: new Date(AB_COOKIE_TTL),
        });
  }
};

export const isUserInTest = () => Boolean(cookies.get(AB_GROUP_NAME));
export const isUserInControlGroup = () =>
  cookies.get(AB_GROUP_NAME) === AB_CONTROL_GROUP_NAME;
export const isUserInTargetGroup = () =>
  cookies.get(AB_GROUP_NAME) === AB_TARGET_GROUP_NAME;
export const getUserTestGroup = () => cookies.get(AB_GROUP_NAME);
