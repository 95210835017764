export const YM_ACCOUNT_ID = 50001859;
export const YM_ACCOUNT_ID_2 = 93862426;
export const YM_REACH_GOAL = 'reachGoal';
export const YM_LEAD = 'lead';
export const YM_STREAM_EVENT_TYPE = {
  prepare: {
    click: {
      0: 'additionalProgramFirstStream',
      1: 'additionalProgramSecondStream',
      2: 'additionalProgramThirdStream',
      3: 'additionalProgramNoDataStream',
    },

    submit: {
      0: 'additionalProgramFirstStreamConfirm',
      1: 'additionalProgramSecondStreamConfirm',
      2: 'additionalProgramThirdStreamConfirm',
      3: 'additionalProgramNoDataStreamConfirm',
    },
  },

  main: {
    click: {
      0: 'mainProgramFirstStream',
      1: 'mainProgramSecondStream',
      2: 'mainProgramThirdStream',
      3: 'mainProgramNoDataStream',
    },

    submit: {
      0: 'mainProgramFirstStreamConfirm',
      1: 'mainProgramSecondStreamConfirm',
      2: 'mainProgramThirdStreamConfirm',
      3: 'mainProgramNoDataStreamConfirm',
    },
  },
};

export const YM_PAYMENT_EVENT_TYPE = {
  click: {
    'Тинькофф Банк': 'newPaymentMethodsTinkoff',
    'Почта-Банк': 'newPaymentMethodsPostBank',
    'ОТП-Банк': 'newPaymentMethodsOtp',
    'Хоум-Кредит-Банк': 'newPaymentMethodsHomeCredit',
  },

  submit: {
    'Тинькофф Банк': 'newPaymentMethodsTinkoffConfirm',
    'Почта-Банк': 'newPaymentMethodsPostBankConfirm',
    'ОТП-Банк': 'newPaymentMethodsOtpConfirm',
    'Хоум-Кредит-Банк': 'newPaymentMethodsHomeCreditConfirm',
  },
};

export const sendYandexMetrikaEvent = (YM_EVENT_TYPE) => {
  if (window && window.ym) {
    console.info(
      `[YM] ${YM_ACCOUNT_ID} -> ${YM_REACH_GOAL} -> ${YM_EVENT_TYPE}`,
    );
    console.info(
      `[YM] ${YM_ACCOUNT_ID_2} -> ${YM_REACH_GOAL} -> ${YM_EVENT_TYPE}`,
    );
    // eslint-disable-next-line no-undef
    ym(YM_ACCOUNT_ID, YM_REACH_GOAL, YM_EVENT_TYPE);
    ym(YM_ACCOUNT_ID_2, YM_REACH_GOAL, YM_EVENT_TYPE);
  }
};

export const getYMStreamEventType = (prepare, type, idx) => {
  const program = prepare ? 'prepare' : 'main';
  return YM_STREAM_EVENT_TYPE[program][type][idx];
};

export const getYMPaymentEventType = (type, provider) =>
  YM_PAYMENT_EVENT_TYPE[type][provider];
