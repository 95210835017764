/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const { setupABTest } = require('./src/helpers/onlinePageABTest');
const { setUtmToCookie } = require('./src/helpers/utmCookie');
const {
  YM_ACCOUNT_ID,
  YM_ACCOUNT_ID_2,
} = require('./src/helpers/yandexMetrika');

exports.onClientEntry = () => {
  setupABTest();
  setUtmToCookie();
  const interval = setInterval(() => {
    if (window && window.SalesNinja) {
      window.SalesNinja.start();
      clearInterval(interval);
    }
  }, 50);
};

exports.shouldUpdateScroll = (routerProps) => {
  const { prevRouterProps, pathname } = routerProps;
  if (pathname === prevRouterProps?.location?.pathname) {
    return false;
  }
  return [0, 0];
};

exports.onRouteUpdate = ({ location }) => {
  if (window && window.ym) {
    window.ym(YM_ACCOUNT_ID, 'hit', location.href.replace(location.origin, ''));
    window.ym(
      YM_ACCOUNT_ID_2,
      'hit',
      location.href.replace(location.origin, ''),
    );
  }

  if (window && window.dataLayer) {
    window.dataLayer.push({ event: 'optimize.activate' });
  }
};
